<template>
  <v-app-bar
    app
    flat
    color="white"
    height="64"
  >
    <v-row>
      <v-col class="flex-grow">
        <v-row class="d-flex flex-nowrap">
          <v-col
            md="auto"
          >
            <search-bar
              v-if="showSearchBar"
              class="client-search"
              menu-class="header-bar-search"
            />
          </v-col>

          <v-col
            v-if="showDataFreshness && dataFreshnessIndicators"
            md="auto"
          >
            <v-dialog
              v-model="toggleDataFreshnessLegend"
              hide-overlay
              max-width="600"
            >
              <template v-slot:activator="{ on }">
                <v-row
                  class="cursor-pointer pt-3 align-center caption flex-nowrap"
                  v-on="on"
                >
                  <v-col class="col-1 px-0 data-refresh-icon">
                    <v-icon
                      class="mr-1"
                      :color="dataFreshnessIndicators.color"
                      small
                    >
                      {{ dataFreshnessIndicators.icon }}
                    </v-icon>
                  </v-col>

                  <v-col
                    v-if="$vuetify.breakpoint.width > 850"
                    class="col-10 ml-2"
                  >
                    <v-row
                      :class="`
                        font-weight-${dataFreshnessIndicators.subtext ? 'bold' : 'medium'}
                        ${dataFreshnessIndicators.subtext ? '' : 'line-height-double'}
                      `"
                    >
                      <v-col class="pa-0 text-capitalize text-truncate data-refresh-text">
                        {{ dataFreshnessIndicators.text }}
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="dataFreshnessIndicators.subtext"
                      class="mt-1 text-capitalize"
                    >
                      <v-col class="pa-0 text-truncate data-refresh-text">
                        {{ dataFreshnessIndicators.subtext }}

                        <span class="font-weight-medium">
                          {{ dataFreshnessIndicators.datetime }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>

              <v-card>
                <v-card-title class="title px-9 py-6">
                  <v-row>
                    <v-col
                      class="flex-grow py-0 darkestblue--text"
                    >
                      Data Status
                    </v-col>

                    <v-spacer />

                    <v-col
                      class="flex-shrink-1 col-2 py-0 text-right"
                    >
                      <v-btn
                        :ripple="false"
                        icon
                        @click.stop="hideLegendDataFreshness"
                      >
                        <v-icon color="brightblue">
                          close
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-divider />

                <v-card-text class="px-6 pb-6">
                  <v-row
                    class="mt-1 text-capitalize"
                  >
                    <v-col class="ma-6">
                      <v-row>
                        <v-col>
                          <v-row class="pr-1 font-weight-bold">
                            Status
                          </v-row>

                          <v-row class="mt-2 font-weight-medium">
                            {{ dataFreshnessIndicators.text }}
                          </v-row>
                        </v-col>
                      </v-row>

                      <v-row
                        v-if="dataFreshnessIndicators.subtext"
                        class="pt-3"
                      >
                        <v-col>
                          <v-row class="pr-1 font-weight-bold">
                            {{ dataFreshnessIndicators.subtext }}
                          </v-row>

                          <v-row class="mt-2 font-weight-medium">
                            {{ dataFreshnessIndicators.datetime }}
                          </v-row>
                        </v-col>
                      </v-row>

                      <v-row
                        v-if="dataFreshnessIndicators.subtext && dataFreshnessIndicators.datetime"
                        class="pt-3"
                      >
                        <v-col>
                          <v-row class="pr-1 font-weight-bold">
                            Timezone
                          </v-row>

                          <v-row class="mt-2 font-weight-medium">
                            Local Time
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        class="d-flex"
        md="auto"
      >
        <v-row class="d-flex flex-nowrap justify-end justify-xl-end justify-lg-end justify-md-end justify-sm-end justify-xs-center align-center pt-1">
          <v-col
            v-if="showFilterBar"
            class="header-action pl-sm-0 pl-md-8 pl-lg-8 pl-xl-8 pl-8 ml-3 mt-1 text-right text-sm-end text-xs-center font-weight-medium"
            style="border-left: 1px solid #F3F3F4FF"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-row
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-col style="margin-top: 2px; max-width: 195px;">
                    <v-autocomplete
                      v-model="syncItemsPerPage"
                      autocomplete="off"
                      class="caption font-weight-medium"
                      dense
                      :items="itemsPerPageOptions"
                      :label="rowsPerPageLabel"
                      hide-details
                      name="rowsPerPage"
                    />
                  </v-col>
                </v-row>
              </template>

              <div class="caption">
                <b>Rows Per Page</b>
                <br>
                {{ rowsPerPageLabel }}
              </div>
            </v-tooltip>
          </v-col>

          <v-col
            v-if="showFilterBar"
            class="header-action pl-sm-0 pr-0 pt-1 mr-3 mt-1 text-center"
            style="border-right: 1px solid #F3F3F4FF;"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-row
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-col
                    class="text-center py-1"
                    style="max-width: 120px;"
                  >
                    <div
                      class="caption font-weight-medium coolgray--text"
                      style="line-height: 21px;"
                    >
                      Page {{ syncPage }} of {{ syncPageCount }}
                    </div>

                    <v-pagination
                      v-model="syncPage"
                      circle
                      :length="syncPageCount"
                      next-icon="navigate_next"
                      prev-icon="navigate_before"
                      :total-visible="0"
                      dense
                      x-small
                    />
                  </v-col>
                </v-row>
              </template>

              <div class="caption">
                <b>Paginate Results</b>
                <br>
                Page {{ syncPage }} of {{ syncPageCount }}
              </div>
            </v-tooltip>
          </v-col>

          <v-col
            :class="showItemBar ? 'header-action col-1 pl-sm-0 text-right text-sm-end  text-xs-center' : 'd-none'"
          >
            <v-badge
              v-if="showItemBar"
              value="showItemBar"
              avatar
              overlap
              color="primary"
              class="mr-4 white--text"
              small
            >
              <template v-slot:badge>
                <v-icon
                  style="margin-top: 2px"
                  color="white"
                  small
                >
                  add
                </v-icon>
              </template>

              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                  <v-btn
                    small
                    text
                    class="white--text create-item"
                    color="primary"
                    depressed
                    v-bind="tooltipAttrs"
                    v-on="tooltipOn"
                    @click.stop="createItem(undefined)"
                  >
                    <img
                      :src="addIcon"
                      width="21"
                    >
                  </v-btn>
                </template>

                <span>Add</span>
              </v-tooltip>
            </v-badge>
          </v-col>

          <v-col
            v-if="showLegendBar"
            class="header-action col-1 pl-sm-0 text-right text-sm-end  text-xs-center"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                <v-btn
                  color="coolgray"
                  icon
                  small
                  v-bind="tooltipAttrs"
                  v-on="tooltipOn"
                  @click="toggleLegend"
                >
                  <v-icon>
                    info
                  </v-icon>
                </v-btn>
              </template>

              <span>Legend</span>
            </v-tooltip>

            <legend-bar />
          </v-col>

          <v-col
            v-if="showExportBar"
            class="header-action col-1 pl-sm-0 text-right text-sm-end  text-xs-center"
          >
            <v-menu
              :disabled="!showExportBar"
              min-width="90"
              offset-y
              nudge-bottom="6"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                    <v-btn
                      :color="showExportBar ? 'coolgray' : 'vuetifygray'"
                      dark
                      icon
                      small
                      title="Export"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        v-bind="tooltipAttrs"
                        v-on="tooltipOn"
                      >
                        system_update
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>Export</span>
                </v-tooltip>
              </template>

              <v-list>
                <v-list-item
                  v-for="item in exportOptions"
                  :key="item.key"
                  :disabled="!item.active"
                  @click="item.callback"
                >
                  <v-list-item-title>
                    <v-col>
                      <v-row v-if="item.key !== 'xlsx'">
                        {{ item.text }}
                      </v-row>

                      <v-row v-else>
                        <export-xlsx
                          :item="item"
                          :show-export-bar="showExportBar"
                        />
                      </v-row>
                    </v-col>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>

          <v-col
            v-if="showLayoutBar"
            class="header-action col-1 pl-sm-0 text-right text-sm-end  text-xs-center"
          >
            <v-menu
              :disabled="!showLayoutBar"
              offset-y
              nudge-bottom="6"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                    <v-btn
                      :color="showLayoutBar ? 'coolgray' : 'vuetifygray'"
                      dark
                      icon
                      small
                      title="Layout"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        v-bind="tooltipAttrs"
                        v-on="tooltipOn"
                      >
                        expand
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>Layout</span>
                </v-tooltip>
              </template>

              <v-list>
                <v-list-item
                  v-for="item in layouts"
                  :key="item.key"
                  :disabled="!item.active"
                  @click="item.callback"
                >
                  <v-list-item-title>
                    <v-col>
                      <v-row>
                        {{ item.text }}
                      </v-row>

                      <v-row
                        v-if="!item.active"
                        class="caption midgray--text"
                      >
                        {{ item.helpText }}
                      </v-row>
                    </v-col>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>

          <v-col
            v-if="showFilterBar"
            class="header-action col-1 pl-sm-0 text-right text-sm-end  text-xs-center"
          >
            <filter-bar />
          </v-col>

          <v-col
            class="header-action col-1 pl-sm-0 text-right text-sm-end  text-xs-center"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="coolgray"
                  icon
                  small
                  target="_blank"
                  title="Release Notes"
                  to="/docs/releases"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    notifications_none
                  </v-icon>
                </v-btn>
              </template>

              <span>Release Notes</span>
            </v-tooltip>
          </v-col>

          <v-col
            class="header-action col-1 pl-sm-0 text-right text-sm-end  text-xs-center"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="coolgray"
                  icon
                  small
                  target="_blank"
                  title="Feedback"
                  href="https://forms.gle/L3rmX38g7DYodqPt6"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    comment
                  </v-icon>
                </v-btn>
              </template>

              <span>Feedback</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import format from 'src/utils/format'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
const { mapActions: mapInternalActions } = createNamespacedHelpers('internal')
const { mapActions, mapGetters, mapState } = createNamespacedHelpers('layout')
const {
  mapActions: mapToolsActions,
  mapGetters: mapToolsGetters,
  mapState: mapToolsState,
} = createNamespacedHelpers('tools')

export default {
  name: 'Header',
  components: {
    ExportXlsx: () => import('@/tools/components/export/ExportXlsx'),
    LegendBar: () => import('src/delivery/components/modals/LegendBar'),
    FilterBar: () => import('src/tools/components/filter/FilterBar'),
    SearchBar: () => import('src/tools/components/search/SearchBar'),
  },
  computed: {
    ...mapAuthGetters(['inDemoMode', 'isInternalWriter', 'permissions']),
    ...mapToolsGetters([
      'currentColumns',
      'currentGroups',
      'currentRowCount',
      'didLastRefreshFail',
      'indicateDataRefreshed',
      'indicateDataRefreshing',
      'isDataRefreshing',
      'isDeliveryRoute',
      'reportKey',
      'storedItems',
    ]),
    ...mapGetters([
      'showDataFreshness',
      'showExportBar',
      'showFilterBar',
      'showLayoutBar',
      'showLegendBar',
      'showSearchBar',
    ]),
    ...mapToolsState({
      internalRoutes: (state) => state.internalRoutes,
      isDataFresh: (state) => state.isDataFresh,
      isLegendModalVisible: (state) => state.isLegendModalVisible,
      itemsPerPage: (state) => state.itemsPerPage,
      itemsPerPageOptions: (state) => state.itemsPerPageOptions,
      page: (state) => state.page,
      pageCount: (state) => state.pageCount,
      reportName: (state) => state.reportName,
      showDataFreshnessLegend: (state) => state.showDataFreshnessLegend,
    }),
    ...mapState({
      icons: (state) => state.icons,
    }),

    syncPage: {
      get() {
        return this.page
      },
      set(value) {
        this.$store.commit('tools/page', value)
      },
    },
    syncPageCount: {
      get() {
        return this.pageCount
      },
      set(value) {
        this.$store.commit('tools/pageCount', value)
      },
    },
    syncItemsPerPage: {
      get() {
        return this.itemsPerPage
      },
      set(value) {
        this.$store.commit('tools/itemsPerPage', value)
      },
    },
    currentRowsIndexLast() {
      const indexLastPossible = this.currentRowCount && (this.syncItemsPerPage * this.syncPage)
      return Math.min(indexLastPossible, this.currentRowCount)
    },
    currentRowsIndexFirst() {
      const remainder = this.syncPage === this.syncPageCount && this.currentRowCount % this.syncItemsPerPage
      return this.currentRowCount && (this.currentRowsIndexLast - (remainder || this.syncItemsPerPage) + 1)
    },
    rowsPerPageLabel() {
      const currentRowCount = this.currentRowCount && format.number(this.currentRowCount)
      return `Rows ${this.currentRowsIndexFirst} - ${this.currentRowsIndexLast} of ${currentRowCount}`
    },

    toggleDataFreshnessLegend: {
      get() {
        return this.showDataFreshnessLegend
      },
      set(value) {
        this.$store.commit('tools/showDataFreshnessLegend', value)
      },
    },
    addIcon() {
      const { icons: { navigation: { add: addIcons } }, reportKey } = this

      return (!!reportKey && addIcons[reportKey]) || null
    },
    showItemBar() {
      const { addIcon, internalRoutes, isInternalWriter, reportKey } = this
      const isAddRoute = () => addIcon && internalRoutes.includes(reportKey)

      return reportKey && isInternalWriter && isAddRoute()
    },
    /**
     * Gets the view data for the data freshness indicator
     *
     * @returns {Object} indicator { color, datetime, icon, subtext, text }
     */
    dataFreshnessIndicators() {
      const {
        indicateDataRefreshed,
        indicateDataRefreshing,
      } = this

      if (indicateDataRefreshing) return indicateDataRefreshing

      if (indicateDataRefreshed) return indicateDataRefreshed

      return false
    },
    layouts() {
      const { currentGroups, showFilterBar, showLayoutBar } = this
      const isToggleGroupsActive = showFilterBar && !!currentGroups?.length && currentGroups[0].groupName !== null

      return [
        {
          active: isToggleGroupsActive,
          helpText: 'See Group & Filter to activate',
          key: 'groups',
          text: 'Toggle Groups',
          callback: this.toggleGroups,
        },
        {
          active: showLayoutBar,
          helpText: 'No saved filters to toggle',
          key: 'summaries',
          text: 'Toggle Saved Filters',
          callback: this.toggleSummaries,
        },
      ]
    },

    exportOptions() {
      const { showExportBar } = this

      return [
        {
          active: showExportBar,
          key: 'csv',
          text: 'CSV',
          callback: this.exportAsCsv,
        },
        {
          active: showExportBar,
          key: 'xlsx',
          text: 'XLSX',
          callback: this.exportAsXlsx,
        },
      ]
    },
  },
  watch: {
    inDemoMode() {
      this.$store.dispatch('tools/getDataRefresh')
    },
  },
  created() {
    this.$store.dispatch('tools/getDataRefresh')
  },
  methods: {
    ...mapActions(['toggleSummaries']),
    ...mapInternalActions(['createItem']),
    ...mapToolsActions(['exportAs', 'toggleGroups']),
    exportAsCsv() {
      const {
        currentColumns: exportColumns,
        currentGroups: exportGroups,
        reportName: exportName,
      } = this
      const exportFormat = 'csv'

      return this.exportAs({ exportColumns, exportFormat, exportGroups, exportName })
    },
    exportAsXlsx() {
      const {
        currentColumns: exportColumns,
        currentGroups: exportGroups,
        reportName: exportName,
      } = this
      const exportFormat = 'xlsx'

      return this.exportAs({ exportColumns, exportFormat, exportGroups, exportName })
    },
    toggleLegend() {
      this.$store.commit('delivery/isLegendModalVisible', !this.isLegendModalVisible)
    },
    hideLegendDataFreshness() {
      this.toggleDataFreshnessLegend = false
    },
  },
}
</script>

<style lang="scss" scoped>
.client-search {
  min-width: 120px;
  max-width: 320px;
}

.header-action {
  min-width: 52px;
}

.data-refresh-icon {
  max-width: 16px;
}

.data-refresh-text {
  min-width: fit-content;
}

.v-select.v-text-field input {
  max-width: 0 !important;
}

.create-item {
  min-width: 21px !important;
  padding: 0 !important;
}

.line-height-double {
  line-height: 2;
}

</style>
